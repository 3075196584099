<template>
  <div class="navbar">
    <ul class="app-nav-list pl-0">
      <li class="app-nav-item" v-for="(menuItem, key) in menus" :key="key">
        <a href="#" class="menu-top-heading">{{ menuItem.module }}</a>
        <ul class="sub-menu mega">
          <li
            v-for="(megaitem, megaitemkey) in menuItem.SubModules"
            :key="megaitemkey"
          >
            <a>{{ megaitem.module }}</a>
            <ul class="sub-menu">
              <li
                v-for="(submega, submegakey) in megaitem.SubModules"
                :key="submegakey"
              >
                <router-link :to="submega.route_path">{{
                  submega.module
                }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["menus"]),
  },
};
</script>

