<template>
	<div>
		<v-menu transition="scale-transition" offset-overflow nudge-bottom="30" nudge-right="20" min-width="200"
			max-width="200" class="userblock-dropdown" light>
			<template v-slot:activator="{ on }">
				<v-btn fab light accent color="accent" small v-on="on">
					<v-icon>mdi-dots-vertical</v-icon>
				</v-btn>
			</template>
			<v-list class="user-dropdown-list">
				<v-list-item :href="userLink.path" v-for="(userLink, key) in data" :key="key">
					<v-icon class="mr-2">{{userLink.icon}}</v-icon>
					<span>{{userLink.title}}</span>
				</v-list-item>
				<v-list-item @click="logOut">
					<v-icon class="mr-2">power_settings_new</v-icon>
					<span>Log Out</span>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex'
	export default {
  methods: {
	  ...mapMutations( [ 
				'resetState' 
		]),
		logOut() {
			this.resetState();
			this.$router.push('/home');
		}
  },
		props: ['data'],
	}
</script>