<template>
  <v-app>
    <vue-snotify></vue-snotify>
    <div class="headers">
      <ww-header></ww-header>
    </div>

    <!-- Menu for mobile -->
    <v-navigation-drawer
      v-model="mobileMenu"
      temporary
      absolute
      dark
      class="sidebar-bg"
    >
      <emb-sidebar></emb-sidebar>
    </v-navigation-drawer>
    <router-view></router-view>
    <ww-footer title="" description="" img=""> </ww-footer>
  </v-app>
</template>

<script>
export default {
  computed: {
    mobileMenu: {
      get() {
        return this.$store.getters.sidebarOpen;
      },
      set(val) {
        this.$store.dispatch("toggleSidebar", val);
      },
    },
  },
  /**
   * Method To set the Rtl While page is opened
   */
  mounted() {},
  methods: {},
};
</script>