//=======================| App Configuration |========================//
export default {
	appLogo: '/static/images/logo.png', 
	appLogoDark: '/static/images/logo.png', 
	brand: 'Back Office',
	copyrightText: '© All Rights Reversed | VB Brothers Pvt Ltd', 
	//theme color
	color: {
      primary: '#015392',
      accent : '#009fda',
	},
	algoliaApiKey: ''
}
