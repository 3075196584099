<template>
	<!--<v-text-field
        reverse
		:label="label"
		:mask="mask"
		:outline="outline"
		:rules="rules"
		single-line
		:value="value"
		prepend-icon="remove"
		append-outer-icon="add"
		@click:append-outer.stop="increase"
		@click:prepend.stop="decrease"
		@change="changed"
	>
	</v-text-field>-->
	<div>
	<v-text-field
		class="input-number-class"
		id="input-number"
		flat 
		x-small
		:label="label"
		prepend-inner-icon="remove"
		append-icon="add"
		:mask="mask"
		:outline="outline"
		:rules="rules"
		single-line
		:value="value"
		v-model="number"
		@change="changed"
		style="max-width: 100px;"
		@click:append.stop="increase"
		@click:prepend-inner.stop="decrease"
		@blur:prepend-inner.stop="blur"
		@blur="blur"
	>
   
  </v-text-field>
  </div>
</template>

<script>
export default {
  watch: {
	  'value': function(val, newVal) {
		  console.log('value: ' + this.value);
		  this.number = this.value;
	  },
	  'input': function(val, newVal) {
		  console.log('input: ' + this.input);
		  this.number = this.input;
		  console.log(this.input);
	  }
  },
  data () {
    return {
		number: this.value,
    }
  },
	name: 'InputNumber',
	props: {
		label: {
			type: String,
			default: '',
		},
		min: {
			type: Number,
			default: 1,
		},
		max: {
			type: Number,
			default: 9999,
		},
		maxLength: {
			type: Number,
			default: 4,
		},
		outline: {
			type: Boolean,
			default: false,
		},
		rules: {
			type: Array,
			default: () => [],
		},
		step: {
			type: Number,
			default: 1,
		},
		value: {
			type: Number,
			default: 1,
		}
	},
	computed: {
		mask() {
			let mask = '';
			for (let i = 0; i < this.maxLength; i++) mask = `${mask}#`;
			return mask;
		}
	},
	methods: {
        changed() {
            this.$emit('input', this.number);
            this.$emit('change', this.number);
        },
		blur() {
            this.$emit('input', this.number);
            this.$emit('blur', this.number);
        },
		increase() {
			console.log('increa');
			if (isNaN(parseInt(this.number))) 
				return this.$emit('input', this.min);
			if (this.number === this.max) return;
			this.number+=1;
			this.$emit('input', this.number);
            this.$emit('change', this.number);
		},
		decrease () {
			if (isNaN(parseInt(this.number))) 
				return this.$emit('input', this.min);
			if (this.number === this.min) return;
			this.number-=1;
			this.$emit('input', this.number);
            this.$emit('change', this.number);
		},
	},
};
</script>
<style>
.v-input__slot {
	border-bottom: 1px solid #f1f1f1 !important;
	border-top: 1px solid #f1f1f1 !important;
	margin-top: 4px !important;
	margin-left: -9px;
}
.v-input__prepend-outer {
	border-bottom: 1px solid #f1f1f1 !important;
	border-top: 1px solid #f1f1f1 !important;
	border-left: 1px solid #f1f1f1 !important;
}
.v-input__prepend-inner {
	border-left: 1px solid #f1f1f1 !important;
	padding-bottom: 2px !important;
    padding-top: 2px !important;
	margin-top: 0px !important;
}
.v-input__append-inner {
	margin-top: 0px !important;
    border-right: 1px solid #f1f1f1 !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
}
.v-input__append-outer {
	border-bottom: 1px solid #f1f1f1 !important;
	border-top: 1px solid #f1f1f1 !important;
	border-right: 1px solid #f1f1f1 !important;
	margin-left: -10px !important;
}
.v-text-field__slot {
	margin-top: -5px;
}
#input-number {
	padding-top: 12px !important;
	text-align: center !important;
}
.v-input__slot::before {
  border-style: none !important;
}
</style>