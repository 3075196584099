//-----------------------| Sidebar Module |-------------------//

import { side_menus } from './data.js';

const initialState  = () => ({
	side_menus,
	sidebarOpen: false
})

const state = {
	side_menus,
	sidebarOpen: false
}

const getters = {
	menus: state => {
		return state.side_menus;
	},
	sidebarOpen: state =>{
		return state.sidebarOpen;
	}
}

const actions = {
   toggleSidebar(context, payload) {
      context.commit('toggleSidebarHandler', payload);
	},
	setMenu(context, payload) {
		context.commit('setMenuHandler', payload);
	},
	changeActiveMenu(context, payload) {
      context.commit('changeActiveMenuHandler', payload);
   },
}

const mutations = {
	toggleSidebarHandler(state, payload) {
	  state.sidebarOpen = payload;
	},
	changeActiveMenuHandler(state, payload) {
		var sidebarMenus = state.adminPanelMenus;
		for( let i = 0; i < sidebarMenus.length; i++){
			let index = sidebarMenus.indexOf(payload);
			if(i == index){
				sidebarMenus[i].active = true
			}else{
				sidebarMenus[i].active = false;
			}
		}
	},
	setMenuHandler(state, payload) {
		state.side_menus = payload;
	},
	resetState (state) {
		const initial = initialState;
		Object.keys(initial).forEach(key => { state[key] = initial[key] })
	},
}

export default {
	state,
	getters,
	actions,
	mutations
}