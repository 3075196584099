export const languages = [
   {
      name: "English",
      locale: "en"
   },
]
export const currencies = [
   {
      id:0,
      img: '/static/images/united-states.png',
      title:'USD',
      symbol:"MVR "
   }
]