<template>
  <div v-if="!empty(uToken)">
    <div class="header-ww-wrap" style="height: 90px !important">
      <div class="header-wrap">
        <div class="top-header-wrap d-block">
          <div class="top-header elevation-1">
            <v-container>
              <v-layout align-center justify-space-between>
                <div
                  class="options site-logo d-inline-block align-items-center"
                >
                  <div
                    class="layout align-left responsive-menu"
                    style="display: inline-table !important"
                  >
                    <v-btn
                      icon
                      color="accent"
                      @click="toggleMobileSidebar"
                      class="toggle-btn d-md-none d-lg-none d-xl-none"
                      large
                    >
                      <i class="material-icons">menu</i>
                    </v-btn>
                  </div>
                  <router-link to="/">
                    <img
                      alt="VB Brothers Pvt Ltd"
                      style="width: auto; height: 60px"
                      height="34"
                      :src="appLogo"
                      width="224"
                    />
                  </router-link>
                </div>
                <div class="hidden-md-and-down d-md-inline-flex">
                  <main-menu></main-menu>
                </div>
                <div class="notifications d-inline-flex align-items-center">
                  <user-block
                    v-if="!empty(uToken)"
                    :data="userLinks"
                  ></user-block>
                  <v-btn
                    fab
                    light
                    accent
                    color="accent"
                    @click="showSideBar"
                    small
                  >
                    <v-icon>mdi-message-processing-outline</v-icon>
                  </v-btn>
                </div>
              </v-layout>
            </v-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "./Menu";
import UserBlock from "./UserBlock";
import AppConfig from "Constants/AppConfig";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({ uToken: "token" }),
  },
  data() {
    return {
      appLogo: AppConfig.appLogo,
      userLinks: [
        {
          icon: "account_circle",
          title: "User Profile",
          path: "/account/profile",
        },
        {
          icon: "settings",
          title: "Account",
          path: "/account/profile",
        },
        {
          icon: "local_post_office",
          title: "Messages",
          path: "/account/profile",
        },
        /*{	
					icon:'power_settings_new',
					title: 'LogOut',
					path:"/session/signin"
				}*/
      ],
    };
  },
  components: {
    mainMenu: Menu,
    userBlock: UserBlock,
  },
  mounted() {},
  methods: {
    toggleMobileSidebar() {
      this.$store.dispatch("toggleSidebar", true);
    },
    showSideBar() {
      this.$store.dispatch("setShowRightSideDrawer", true);
    },
  },
};
</script>