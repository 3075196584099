import UserPanel from 'Container/UserPanel';

const Home = () => import('Views/Home');
const Profile = () => import('Views/UserAccount/Profile.vue');
const Account = () => import('Views/UserAccount/Account.vue');
const EditProfile = () => import ('Views/UserAccount/EditProfile.vue');
const EditAddress = () => import ('Views/UserAccount/EditAddress.vue');
const EditProfileInfo = () => import ('Views/UserAccount/EditProfileInfo.vue');
const Register = () => import('Views/Sessions/Register');
const ForgotPassword = () => import('Views/Sessions/ForgotPassword');
const ThankYou = () => import('Views/Sessions/ThankYou');
const SignIn = () => import('Views/Sessions/SignIn');


export default {
	path: '/',
	component: UserPanel,
	redirect:'/home',
	children:[
		{ 
			path: '/home',
			component: Home ,
			meta: {
				requiresAuth: true
			}
		},
		{ 
			path: '/account',  
			component: Account, 
			name:'Account',
			children: [
				{
					path: '/account/profile',name:'Profile',component: Profile,
				},
				{
					path: '/account/profile/edit',name:'EditProfile',component: EditProfile
				},
				{
					path: '/account/profile/edit',name:'EditProfileInfo',component: EditProfileInfo,
				},
        ]
		},
		{ 
			path: '/forgot-password',  
			component: ForgotPassword, 
			name:'ForgotPassword'
		},
		{ 
			path: '/thank-you',  
			component: ThankYou, 
			name:'ThankYou'
		},
		{ 
			path: '/signin',  
			component: SignIn, 
			name:'SignIn'
		},
	]
}