<template>
  <v-card :loading="loading" class="mx-auto" max-width="374">
    <template slot="progress">
      <v-progress-linear
        color="yellow"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-toolbar color="yellow" dark>
      <v-toolbar-title style="color: #000">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon color="black">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-chip-group
        v-model="selection"
        active-class="deep-purple accent-4 white--text"
        column
      >
        <v-chip>30 Mins</v-chip>
        <v-chip>1 Hour</v-chip>
        <v-chip>2 Hour</v-chip>
        <v-chip>3 Hours ></v-chip>
      </v-chip-group>
    </v-card-text>
    <v-list two-line>
      <v-list-item-group v-model="selected" active-class="light-green--text">
        <template v-for="(item, index) in items">
          <v-list-item :key="item.title">
            <v-list-item-avatar>
              <v-img :src="item.avatar"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="item.title"></v-list-item-title>
              <v-list-item-subtitle
                v-html="item.subtitle"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text
                class="green-text"
                v-text="item.action"
              ></v-list-item-action-text>
              <v-btn icon>
                <v-icon @click="reply(item)" color="green darken-1"
                  >mdi-reply</v-icon
                >
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: null,
    },
  },
  created() {},
  components: {},
  data() {
    return {
      loading: false,
      selected: [0],
      selection: null,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    reply(item) {
      console.log(item);
    },
  },
};
</script>