<template>
  <div class="sidebar-panel" v-if="drawer">
    <v-layout justify-end mt-0 v-if="showButton">
      <v-btn
        class="sidebar-toggle mx-4"
        color="accent"
        dark
        @click.stop="drawer = !drawer"
      >
        Unread Messages : 100
      </v-btn>
    </v-layout>
    <v-navigation-drawer fixed width="540" right v-model="drawer" temporary>
      <div class="accent pa-1">
        <v-icon @click="drawer = false" class="white--text mb-4"
          >mdi-close-circle-outline</v-icon
        >
      </div>
      <div class="cart-content">
        <conversations
          title="Conversations"
          :items="conversations"
        ></conversations>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Conversations from "../../views/OrderManagement/Conversations.vue";

export default {
  components: { Conversations },
  props: {
    showButton: {
      type: Boolean,
    },
  },
  created() {
    //this.$store.dispatch("setShowRightSideDrawer", false);
  },
  watch: {
    showRightSideDrawer: function (val, newVal) {
      console.log("dsd");
      this.drawer = this.showRightSideDrawer;
    },
    drawer: function (val, newVal) {
      this.$store.dispatch("setShowRightSideDrawer", this.drawer);
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapGetters(["showRightSideDrawer"]),
  },
  methods: {},
};
</script>


