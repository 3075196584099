import Vue from 'vue'
import { mapGetters } from 'vuex';
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import firebase from 'firebase/app';
import 'firebase/messaging';

Vue.use(VueSweetalert2);

export default Vue.mixin({
    computed: {
		...mapGetters({userToken: 'token', user: 'user'}),
    },
    data() {
        return {
            channelTypes: [
                { id: 'viber', title: 'Viber', icon: '/images/viber.png' },
                { id: 'facebook', title: 'Facebook', icon: '/images/facebook.png' },
                { id: 'viberbot', title: 'Viber Bot', icon: '/images/viberbot.png' },
                { id: 'website', title: 'Website', icon: '/images/web.png' },
                { id: 'sms', title: 'SMS Message', icon: '/images/sms.png' },
                { id: 'twitter', title: 'Twitter', icon: '/images/twitter.png' }
            ]
        };
    },
    methods: {
      empty(text){
        if(text == '' || text == undefined) {
            return true;
        }
        else {
            return false;
        }
      }, 
      getUserToken() {
          return this.userToken;
      },
      searchData(list, search_string) {
        if(this.empty(search_string)) {
           return list;
        }
        return list.filter(item => {
           let flag;
           for(let prop in item){
              flag = false;
              if(!this.empty(item[prop])) {
                 flag = item[prop].toString().toLowerCase().includes(search_string.toLowerCase());
              }
              if(flag)
              break;
           }
           return flag;
        });``
     },
        async post(url, param = null) {
            axios.create({
                baseURL: 'https://vb.firebaseio.com/'
            });
        const headers = { 
            "Authorization": "Bearer " + this.userToken,
            'Content-Type':  'application/json',
        };
        //this.showProgress();
        var formdata = new FormData();
        if(param != null) {
            for (let [key, value] of Object.entries(param)) {
                formdata.append(key, value);
            }
        }
         formdata.append('sales_channels_id', this.sales_channels_id);
         if (!this.empty(this.user) && !this.empty(this.user.id)) {
             formdata.append('cid', this.user.id);
         }
         let self = this;
        // console.log(self.host_api + url);
        return await axios.post(self.host_api + url, formdata, { headers })
        .then(function (response) {
            //self.hideProgress();
            return response;
        })
        .catch(function (error) {
            throw error;
        })
     },
     handleErrors(error){
        if(!error.response) {
          //this.hideProgress();
          this.$swal.fire({
              title: 'Error!',
              html: error,//'Could not connect to the server.',
              icon: 'error',
              confirmButtonText: 'Alright'
          });
          return;
        }
        if(error.response.status == 422 && !this.empty(error.response.data.valid)) {
            let validation = error.response.data.valid;
            let errors = '';
            Object.keys(validation).forEach(key => {
                let e = validation[key];
                e.forEach(item => {
                    errors += item + '<br/>';
                });
            })
            this.$swal.fire({
                title: 'Validations Failed!',
                html: errors,
                icon: 'error',
                confirmButtonText: 'Alright'
            });
        }
        else if(error.response.status == 422 && !this.empty(error.response.data.error)) {
          this.$swal.fire({
              title: 'Validations Failed!',
              html: error.response.data.error,
              icon: 'error',
              confirmButtonText: 'Alright'
          });
      }
        else if(error.response.status == 500 && !this.empty(error.response.data.error)) {
            this.$swal.fire({
                title: 'Error!',
                html: error.response.data.error,
                icon: 'error',
                confirmButtonText: 'Alright'
            });
        }
        else if(!this.empty(error.response.data.error)) {
            this.$swal.fire({
                title: 'Error!',
                html: error.response.data.error,
                icon: 'error',
                confirmButtonText: 'Alright'
            });
        }
        else {
          this.$swal.fire({
            title: 'Error!',
            html: 'Oops!, unkown Error',
            icon: 'error',
            confirmButtonText: 'Alright'
        });
        }
      },
      showError(error) {
        this.$swal.fire({
            title: 'Error!',
            html: error,
            icon: 'error',
            confirmButtonText: 'Alright'
        });
      },
      showInfo(message) {
        this.$swal.fire({
            title: 'Cool!',
            html: message,
            icon: 'info',
            confirmButtonText: 'Cool'
        });
      },
        getBase64(file) {
			return new Promise(function (resolve, reject) {
				let reader = new FileReader();
				let imgResult = "";
				reader.readAsDataURL(file);
				reader.onload = function () {
				imgResult = reader.result;
				};
				reader.onerror = function (error) {
				reject(error);
				};
				reader.onloadend = function () {
				resolve(imgResult);
				};
			});
		},
        getObjectFromArray(array, key, value) {
            if (array == null || array.length <= 0)
                return;
            return array.filter(function (keyObject) {
                return keyObject[key].toString().toLowerCase() == value.toString().toLowerCase()
            }
            );
        },
        fcmRegister(user, force = false) {
            try {
                firebase.initializeApp(this.fcm_config);
    
                let self = this;
                const messaging = firebase.messaging();
                messaging
                    .requestPermission()
                    .then(function () {
                        //console.log("Notification permission granted.");
                        return messaging.getToken()
                    })
                    .then(function (token) {
                        if (((self.fcmToken != '' || self.fcmToken != undefined) && self.fcmToken != token && !self.empty(self.user)) || force) {
                            let f = {
                                'sales_channel_customers_id': user.id,
                                'token': token
                            }
                            self.post('ecom/member/register-fcm', f)
                                .catch(response => {
                                    self.$store.dispatch("setFcmToken", token);
                                });
                        }
                        //console.log(token);
                    })
                    .catch(function (err) {
                        console.log("Unable to get permission to notify.", err);
                    });
            }
            catch (err) {
                //this.showError(err);
            }
        }
    },
  });