
import {languages, currencies} from "./data";

const initialState = {
	languages,
	selectedLocale: languages[0],
	currencies,
	selectedCurrency: currencies[0],
	rtlLayout: false,
	progressOverlayStatus: false,
	token: '',
	user: null,
	showRightSideDrawer: false,
	fcmToken: null,
}

const state = {
	languages,
	selectedLocale: languages[0],
	currencies,
	selectedCurrency: currencies[0],
	rtlLayout: false,
	progressOverlayStatus: false,
	token: '',
	user: null,
	showRightSideDrawer: false,
	fcmToken: null,
}


// getters
const getters = {
	languages: state => {
		return state.languages;
	},
	selectedLocale: state => {
		return state.selectedLocale;
	},
	currencies: state =>{
		return state.currencies;
	},
	selectedCurrency: state => {
		return state.selectedCurrency;
	},
	rtlLayout: state =>{
		return state.rtlLayout;
	},
	token: state => {
		return state.token;
	},
	user: state => {
		return  state.user;
	},
	showRightSideDrawer: state => {
		return  state.showRightSideDrawer;
	},
	fcmToken: state => {
		return  state.fcmToken;
	}
}

// actions
const actions = {
	changeLanguage(context, payload) {
		context.commit('changeLanguageHandler', payload);
	},
	changeCurrency(context, payload){
		context.commit('changeCurrency', payload);
	},
	changeRtlLayout(context){
		context.commit('rtlLayoutHandler');
	},
	setToken(context, payload) {
		console.log(payload);
		context.commit('setTokenHandler', payload);
	},
	setUser(context, payload) {
		context.commit('setUserHandler', payload);
	},
	setShowRightSideDrawer(context, payload) {
		context.commit('setShowRightSideDrawerHandler', payload);
	},
	setFcmToken(context, payload) {
		context.commit('setFcmTokenHandler', payload);
	}
}

// mutations
const mutations = {
	/**
    * method for setting language state
   */
	changeLanguageHandler(state, language) {
		state.selectedLocale = language;
	},
	/**
    * method for setting currency
   */
	changeCurrency(state, currencies){
		state.selectedCurrency = currencies;
	},
	/**
    * method for setting rtl layout
   */
	rtlLayoutHandler(state){
		state.rtlLayout = !state.rtlLayout;
	},
	setTokenHandler(state, security_token) {
		state.token = security_token;
	},
	setUserHandler(state, userObject) {
		state.user = userObject;
	},
	setShowRightSideDrawerHandler(state, userObject) {
		state.showRightSideDrawer = userObject;
	},
	setFcmTokenHandler(state, userObject) {
		state.fcmToken = userObject;
	},
	resetState (state) {
		const initial = initialState;
		Object.keys(initial).forEach(key => { state[key] = initial[key] })
	},
	
}

export default {
	state,
	getters,
	actions,
	mutations
}
