/**
* VueShop Store
*/

import Vue from 'vue'
import Vuex from 'vuex'

// modules
import sidebar from './modules/sidebar';
import settings from './modules/settings';

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export const store = new Vuex.Store({
    plugins: [createPersistedState({overwrite: true})],

    modules: {
        sidebar,
        settings,
    },
    
})
