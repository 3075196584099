/**
 * VueShop Global Components
 */
import Header from './components/Layouts/Header/Header.vue';
import Footer from './components/Layouts/Footer/Footer.vue';
import InputNumber from './components/Global/InputNumber';
import Sidebar from './components/Layouts/Sidebar/Sidebar';
import SidebarPanel from './components/Global/SidebarPanel';
import Timer from './components/Global/Timer';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ConfirmationDialog from './components/Global/ConfirmationDialog';


const GlobalComponents = {
   install(Vue) {
      Vue.component('wwHeader', Header);
      Vue.component('wwFooter', Footer);
      Vue.component('confirmationDialog', ConfirmationDialog);
      Vue.component('inputNumber', InputNumber);
      Vue.component('embSidebar', Sidebar);
      Vue.component('embSidebarPanel', SidebarPanel);
      Vue.component('embTimer', Timer);
      Vue.component('embPerfectScrollbar', VuePerfectScrollbar);
   }
}

export default GlobalComponents